import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { QRCodeModule } from "angularx-qrcode";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton } from "@angular/material/button";

@Component({
	selector: "app-qr-code",
	templateUrl: "./qr-code.component.html",
	styleUrls: ["./qr-code.component.scss"],
	imports: [MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, MatDialogContent, QRCodeModule],
})
export class QrCodeComponent {
	qrContent = "";

	constructor(@Inject(MAT_DIALOG_DATA) private data: { content: string }) {
		this.qrContent = data.content;
	}
}
